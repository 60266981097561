@import '../node_modules/@syncfusion/ej2-base/styles/material.css';
@import '../node_modules/@syncfusion/ej2-buttons/styles/material.css';
@import '../node_modules/@syncfusion/ej2-calendars/styles/material.css';
@import '../node_modules/@syncfusion/ej2-dropdowns/styles/material.css';
@import '../node_modules/@syncfusion/ej2-inputs/styles/material.css';
@import '../node_modules/@syncfusion/ej2-navigations/styles/material.css';
@import '../node_modules/@syncfusion/ej2-popups/styles/material.css';
@import '../node_modules/@syncfusion/ej2-splitbuttons/styles/material.css';
@import "../node_modules/@syncfusion/ej2-react-grids/styles/material.css";

.fabric .empimg {
    background-color: #0078d6;
}

.material .empimg {
    background-color: #e3165b;
}

.highcontrast .empimg {
    background-color: #ffd939;
}

_:-ms-fullscreen,
:root .highcontrast .e-userimg.sf-icon-Male::before,
_:-ms-fullscreen,
:root .highcontrast .e-userimg.sf-icon-FeMale::before {
    color: black;
}

.highcontrast .e-userimg.sf-icon-Male::before,
.highcontrast .e-userimg.sf-icon-FeMale::before {
    color: black;
}

.bootstrap .empimg {
    background-color: #0078d6;
}

.bootstrap4 .empimg {
    background-color: #0078d6;
}

hr {
    font-size: '1.3em';
    width: 70%;
    margin-left: auto;
    margin-right: auto;
}

.e-altrow {
    background: beige;
}

.loader-small .loader-inner {
    transform: scale(2.0, 2.0);
}

.loader-hidden {
    display: none;
}

.loader-active {
    display: block;
}

#Emptext {
    top: -12px;
    left: 41px;
    position: relative;
}

.empimg {
    height: 27px;
    width: 29px;
    border-radius: 14px;
}

#overviewgrid.e-rtl #Emptext {
    top: -12px;
    left: -41px;
    position: relative;
}

.e-userimg.sf-icon-Male::before,
.e-userimg.sf-icon-FeMale::before {
    font-size: 13px;
}

.e-userimg.sf-icon-Male,
.e-userimg.sf-icon-FeMale {
    margin: 0px 8px;
    line-height: 26px;
    color: white;
    font-size: 13px;
}

.e-userimg.sf-icon-Male::before {
    content: '\e700';
}

.e-userimg.sf-icon-FeMale::before {
    content: '\e701';
}


.highcontrast #overviewgrid td.e-rowcell.e-selectionbackground.e-active {
    background-color: transparent;
    color: white;
}

.e-bigger #overviewgrid .statustxt {
    position: relative;
}

.rating .star {
    font-size: 132%;
    color: lightgrey;
}

.rating .star.checked {
    color: #ffa600;
}

.rating .star:before {
    content: '★';
}

#overviewgrid td.e-rowcell.e-selectionbackground.e-active {
    background-color: transparent;
}

.e-image {
    height: 13px;
    width: 14px;
}

td.e-rowcell .statustxt.e-activecolor {
    color: #00cc00;
    position: relative;
    top: 9px;
}

td.e-rowcell .statustxt.e-inactivecolor {
    color: #e60000;
    position: relative;
    top: 9px;
}

.statustemp.e-inactivecolor {
    background-color: #ffd7cc;
    width: 64px;
}

.statustemp.e-activecolor {
    background-color: #ccffcc;
    width: 57px;
}

.statustxt.e-activecolor {
    color: #00cc00;
}

.statustxt.e-inactivecolor {
    color: #e60000;
}

.statustemp {
    position: relative;
    height: 19px;
    border-radius: 5px;
    text-align: center
}

.highcontrast #msg {
    color: #FFFFFF
}

#msg {
    font-family: "Segoe UI", "GeezaPro", "DejaVu Serif", sans-serif;
    font-size: 14px;
    font-weight: 400;
    opacity: 0.95;
    color: #333;
    float: right;
    margin-top: 3px;
}

@media only screen and (max-width: 370px) {
    #msg {
        float: left;
        margin-top: 3px;
    }

    #overviewgrid {
        margin-top: 36px;
    }

}


@media only screen and (max-width: 1600px) {

    #BtnBloccaPagine,
    #BtnCaricaDati {
        margin-inline-start: -25%;
    }

    #TestoDatiAggiornati {
        margin-inline-start: -25%;
    }
}


@media only screen and (max-width: 1300px) {
    #logoAllC {
        width: 95% !important;
    }

    #imgLogoDp {
        width: 50% !important;
    }

    #monitorScritta {
        font-size: 3.5vw !important;
    }

    #relise {
        font-size: 0.6vw !important;
    }

    #GridTotaliSinistriChiusi,
    #GridTotaliSinistriEntratiChiusi {
        margin-top: 30PX !important;
    }

    #BtnBloccaPagine,
    #BtnCaricaDati {
        margin-inline-start: -75%;
    }

    #BtnBloccaPagine {
        margin-top: 20%;
        box-sizing: content-box !important;
        align-items: flex-end !important;
    }

    #TestoDatiAggiornati {
        display: inline-block !important;
        margin-inline-start: 30%;
        width: 300px !important;
        font-size: 1.1vw !important;
    }

    #e-content-TabMonitor_1 #legendaCustom2 {
        margin-top: -20px !important;
    }

    #GridPeriti_toolbarItems {
        margin-bottom: 45px !important;
    }

    #GridPeriti_toolbarItems .e-toolbar-left {
        margin-top: 50px !important;
    }

    #GridPeriti_toolbarItems .e-toolbar-right {
        margin-top: 50px !important;
    }

    span {
        font-size: 1vw !important;
    }

    th.e-headercell {
        padding-left: 5px !important;
        padding-right: 5px !important;
    }

    td.e-rowcell {
        padding-left: 0px !important;
        padding-right: 0px !important;
    }


}

@media only screen and (max-width: 1050px) {

    #defaultDialog1 .container-fluid .row {
        overflow: scroll !important;
        height: 420px !important;
    }

    #BtnBloccaPagine {
        margin-top: 20%;
        box-sizing: content-box !important;
        align-items: flex-end !important;
    }

    #TestoDatiAggiornati {
        display: inline-block !important;
        margin-inline-start: 30%;
        width: 300px !important;
        font-size: 1.1vw !important;
    }

    #logoAllC {
        width: 110% !important;
        margin-left: -10px !important;
    }

    #monitorScritta {
        margin-left: -50px !important;
        font-size: 3.5vw !important;
    }

    #imgLogoDp {
        width: 60% !important;
    }

    #relise {
        font-size: 0.7vw !important;
    }

    #e-content-TabMonitor_0 #legendaCustom {
        margin-top: -80px !important;
    }

    #e-content-TabMonitor_1 #legendaCustom2 {
        margin-top: -110px !important;
    }

    #BtnBloccaPagine,
    #BtnCaricaDati {
        margin-inline-start: -85%;
    }



    th.e-headercell {
        padding-left: 5px !important;
        padding-right: 5px !important;
    }

    span {
        font-size: 1.2vw !important;
    }

    .e-rowcell {
        font-size: 1.3vw !important;
        padding-left: 0px !important;
        padding-right: 0px !important;
    }

    #BtnVediSospesi {
        margin-inline-start: -85%;
    }

    #GridPeriti_toolbarItems {
        margin-bottom: 45px !important;
    }

    #GridPeriti_toolbarItems .e-toolbar-left {
        margin-top: 50px !important;
    }

    #GridPeriti_toolbarItems .e-toolbar-right {
        margin-top: 50px !important;
    }

}

@media only screen and (max-width: 850px) {

    #ChartSinistriEntratiChiusi,
    #ChartRestituzionePeriti,
    #ChartSinistriChiusi {
        margin-left: -2%;
    }

    #GridTotaliSinistriChiusi {
        margin-top: 40px !important;
    }

    #ChartSinistriEntratiChiusi,
    #ChartRestituzionePeriti,
    #ChartSinistriChiusi {
        margin-left: -2%;
    }

    #GridTotaliSinistriChiusi {
        margin-top: 40px !important;
    }

    #logoAllC {
        width: 170% !important;
        margin-left: -30px !important;
    }

    #monitorScritta {
        font-size: 3.5vw !important;
        margin-left: 28px !important;
    }


    #imgLogoDp {
        width: 60% !important;
        display: inline !important;

    }

    #casellaDPLogo {
        text-align: center !important;
    }

    #relise {
        font-size: 0.7vw !important;
    }

    #e-content-TabMonitor_0 #legendaCustom {
        margin-top: -320px !important;
    }

    #e-content-TabMonitor_1 #legendaCustom2 {
        margin-top: -345px !important;

    }

    #defaultDialog1 .container-fluid .row {
        overflow: scroll !important;
        height: 225px !important;
    }



    #GridPeriti_toolbarItems {
        margin-bottom: 45px !important;
    }

    #GridPeriti_toolbarItems .e-toolbar-left {
        margin-top: 50px !important;
    }

    #GridPeriti_toolbarItems .e-toolbar-right {
        margin-top: 50px !important;
    }

    #ChartSinistriChiusi_svg {
        height: 250px;
    }

    #BtnBloccaPagine,
    #BtnCaricaDati {
        font-size: 10px;
        margin-inline-start: -75%;
    }

    #BtnBloccaPagine {
        margin-top: 30%;
        box-sizing: content-box !important;
        align-items: flex-end !important;
    }

    #TestoDatiAggiornati {
        width: 200px !important;
        display: inline-block !important;
        margin-inline-start: 40%;
        font-size: 1.1vw !important;
    }



    td.e-rowcell {
        font-size: 1.5vw !important;
        box-sizing: content-box !important;

        padding-left: 0px !important;
        padding-right: 0px !important;

    }

    .e-headercell div span {
        font-size: 1.3vw !important;

    }

    .e-gridcontent,
    .e-content {
        width: 100% !important;
        overflow-x: hidden !important;
        overflow-y: hidden !important;
    }

    .e-grid .e-headercelldiv {
        /* qui l intestazioni */
        padding-left: 0 !important;
        padding-right: 0 !important;
    }

    #mediaGiorniChiusuraDiv,
    #apertiDiv {
        display: block;

    }

    #rowScritteEntrChius {
        display: block;
    }

    td.e-rowcell>div>span {
        font-size: 1.5vw !important;
    }

    th.e-headercell>div>div>span {
        font-size: 1.5vw !important;
    }

    th.e-headercell {
        padding-left: 10px !important;
        padding-right: 10px !important;
    }

    #vediSospesiDialog .e-dlg-header-content .e-dlg-header span {
        font-size: 17px !important;
        margin-left: 10px !important;
    }

    #GridSegreteriaACE th.e-headercell {
        padding-left: 8px !important;
        padding-right: 8px !important;
    }

    div.e-headercelldiv>div>span {
        box-sizing: content-box !important;
    }

    div.e-gridheader {
        display: table !important;
    }

    #divBottoneVediSospesi {
        margin-right: 5% !important;
    }

    #BtnVediSospesi {
        margin-left: 85%;
    }



    div.e-card-header-title {
        font-size: 1.7vw !important;
    }

    #GridReferentiTecnici .e-headercontent .e-headercelldiv {
        text-align: center !important;

    }

    #GridSegreteriaACE .e-headercontent tr.e-columnheader th div.e-headercelldiv {
        text-align: center !important;
    }

    #GridControlloReferenti .e-headercontent .e-columnheader th div {
        text-align: center !important;
    }

    #GridCorrettori .e-headercontent .e-columnheader .e-headercelldiv {
        text-align: center !important;
    }

    #GridPeriti .e-gridcontent .e-row td.e-rowcell span {
        font-size: 1.5vw !important;
    }

    #GridTotaliSinistriChiusi .e-columnheader .e-headercell .e-headercelldiv {
        white-space: normal !important;
        line-height: 18px !important;
        overflow-wrap: break-word !important;
        height: 56px !important;
    }

    #GridTotaliSinistriChiusi .e-columnheader .e-headercell {
        height: 56px !important;
    }



}





#samplecontainer {
    padding-top: 5px;
}

.e-grid .e-pager,
.e-acrdn-header {
    text-align: center;
}

#myProgress {
    position: relative;
    height: 18px;
    width: 10em;
    text-align: left;
    background-color: white;
}

.highcontrast #overviewgrid #myProgress {
    background-color: black;
}

#myBar {
    position: absolute;
    width: 10%;
    height: 100%;
    background-color: #00b300;
}

#myBar.progressdisable {
    background-color: #df2222;
}

#label {
    position: relative;
    left: 10px;
    line-height: 18px;
    font-size: 10px;
    color: white;
}

.ms-chip-customize #multi-customize {
    width: 80%;
    margin: 0 auto;
    min-width: 185px;
}

.e-bigger .ms-chip-customize #multi-customize {
    width: 100%;
}

.ms-chip-customize .e-multi-select-wrapper .e-chips {
    opacity: 0.9;
}

.ms-chip-customize .e-multi-select-wrapper .e-chips:hover {
    opacity: 1;
}

.ms-chip-customize .e-multi-select-wrapper .e-chips .e-chips-close.e-icon::before,
.ms-chip-customize .e-multi-select-wrapper .e-chips .e-chipcontent,
.ms-chip-customize .e-multi-select-wrapper .e-chips .e-chipcontent:hover {
    color: #ffffff;
}

.e-chips.aliceblue,
.e-chips.aliceblue:hover {
    background-color: #F0F8FF !important;
    font-weight: bold;
}

.e-chips.aqua,
.e-chips.aqua:hover {
    background-color: #00FFFF !important;
    font-weight: bold;
}

.e-chips.aquamarine,
.e-chips.aquamarine:hover {
    background-color: #7FFFD4 !important;
    font-weight: bold;
}

.e-chips.beige,
.e-chips.beige:hover {
    background-color: #F5F5DC !important;
    font-weight: bold;
}

.e-chips.burlywood,
.e-chips.burlywood:hover {
    background-color: #DEB887 !important;
    font-weight: bold;
}

.e-chips.chartreuse,
.e-chips.chartreuse:hover {
    background-color: #7FFF00 !important;
    font-weight: bold;
}

.e-chips.coral,
.e-chips.coral:hover {
    background-color: #FF7F50 !important;
    font-weight: bold;
}

.e-chips.cornsilk,
.e-chips.cornsilk:hover {
    background-color: #FFF8DC !important;
    font-weight: bold;
}

.e-chips.lightsalmon,
.e-chips.lightsalmon:hover {
    background-color: #FFA07A !important;
    font-weight: bold;
}

.e-chips.darkturquoise,
.e-chips.darkturquoise:hover {
    background-color: #00CED1 !important;
    font-weight: bold;
}

.e-chips.dodgerblue,
.e-chips.dodgerblue:hover {
    background-color: #1E90FF !important;
    font-weight: bold;
}

.e-chips.gainsboro,
.e-chips.gainsboro:hover {
    background-color: #DCDCDC !important;
    font-weight: bold;
}

.e-chips.gold,
.e-chips.gold:hover {
    background-color: #FFD700 !important;
    font-weight: bold;
}

.e-chips.greenyellow,
.e-chips.greenyellow:hover {
    background-color: #ADFF2F !important;
    font-weight: bold;
}

.e-chips.khaki,
.e-chips.khaki:hover {
    background-color: #F0E68C !important;
    font-weight: bold;
}

.e-chips.lavender,
.e-chips.lavender:hover {
    background-color: #E6E6FA !important;
    font-weight: bold;
}

.e-chips.lawngreen,
.e-chips.lawngreen:hover {
    background-color: #7CFC00 !important;
    font-weight: bold;
}

.e-chips.lightblue,
.e-chips.lightblue:hover {
    background-color: #ADD8E6 !important;
    font-weight: bold;
}

.e-chips.mediumspringgreen,
.e-chips.mediumspringgreen:hover {
    background-color: #00FA9A !important;
    font-weight: bold;
}

.e-chips.mistyrose,
.e-chips.mistyrose:hover {
    background-color: #FFE4E1 !important;
    font-weight: bold;
}

.e-chips.plum,
.e-chips.plum:hover {
    background-color: #DDA0DD !important;
    font-weight: bold;
}

.e-chips.sandybrown,
.e-chips.sandybrown:hover {
    background-color: #F4A460 !important;
    font-weight: bold;
}

.e-chips.thistle,
.e-chips.thistle:hover {
    background-color: #D8BFD8 !important;
    font-weight: bold;
}

.e-chips.turquoise,
.e-chips.turquoise:hover {
    background-color: #40E0D0 !important;
    font-weight: bold;
}

.e-chips.yellow,
.e-chips.yellow:hover {
    background-color: #FFFF00 !important;
    font-weight: bold;
}

.e-chips.tomato,
.e-chips.tomato:hover {
    background-color: #FF745C !important;
    font-weight: bold;
}

.e-chips.violet,
.e-chips.violet:hover {
    background-color: #EE82EE !important;
    font-weight: bold;
}

#ChartSinistriTUS_Series_0_TrendLine_0 {
    stroke-dasharray: 10px 10px;
    stroke-linejoin: round;
    stroke-linecap: round;
    -webkit-animation: dash 1s linear infinite;
    animation: dash 1s linear infinite;
}

@-webkit-keyframes dash {
    100% {
        stroke-dashoffset: -20px;
    }
}

@keyframes dash {
    100% {
        stroke-dashoffset: -20px;
    }
}

.tooltip-container {
    background-color: #F0FFF0;
    border: 2px solid purple;
    border-radius: 8px;
    width: 13vw;
    transform: translatex(-46%);
    position: fixed;
    display: inline;
}

.e-row[aria-selected="true"] .e-customizedExpandcell {
    background-color: #e0e0e0;
}

.e-grid.e-gridhover tr[role='row']:hover {
    background-color: #eee;
}

.e-imageAccordion:before {
    content: "\e984";
}

#js-licensing {
    display: none;
}

#chartTortaFatturato_0>div>svg>g>g:nth-child(2)>g:nth-child(2)>g>g:nth-child(3) {
    opacity: 0.0;
    display: none;
}

label[for*="visioneTorta1_"]:not(label[for="visioneTorta1_0"]) {
    display: none;
}

#GridObiettivi>div.e-gridcontent>div {
    overflow-y: hidden !important;
}

/* Licenza nascosta Syncfusion */
#js-licensing {
    display: none;
}

